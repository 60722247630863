@import 'bootstrap/scss/forms/form-range';

input[type='range'] {
  &::-webkit-slider-thumb {
    background: escape-svg(
      url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">\
        <polygon points="8,0 0,8, 16,8" fill="#{$primary}" />\
        </svg>')
    );

    &:hover,
    &:active {
      background: escape-svg(
        url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">\
          <polygon points="8,0 0,8, 16,8" fill="#{$primary}" />\
          <rect width="16" height="16" fill="#{$primary}" y="8" />\
          </svg>')
      );
      
    }
  }
}

input[type="range"]:focus::-webkit-slider-thumb {
  box-shadow: none !important;
  background: escape-svg(
    url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">\
      <polygon points="8,0 0,8, 16,8" fill="#{$primary}" />\
      <rect width="16" height="16" fill="#{$primary}" y="8" />\
      </svg>')
    );
}

input[type="range"]:focus-visible::-webkit-slider-thumb {
  box-shadow: none !important;
  outline: 2px solid $primary !important;
  outline-offset: 2px;
}



input[type='range']::-webkit-slider-thumb {
  display: block;
  margin-top: 5px;
  box-shadow: none;
}

input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  background-color: transparent;
  border-radius: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px;
  border-color: transparent transparent currentColor;
  transform: translateY(16px);
}
