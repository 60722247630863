$scrollbar-size-sm: 5px !default;
$scrollbar-size: 10px !default;
$scrollbar-size-large: 17px !default;
$scrollbar-button-down: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$scrollbar-thumb-color}" d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' !default;
$scrollbar-hover-button-down: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$scrollbar-thumb-active-color}" d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' !default;
$scrollbar-button-up: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$scrollbar-thumb-color}" d="M7 14l5-5 5 5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' !default;
$scrollbar-hover-button-up: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#{$scrollbar-thumb-active-color}" d="M7 14l5-5 5 5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>' !default;

*::-webkit-scrollbar {
  width: $scrollbar-size;
}

*::-webkit-scrollbar:horizontal {
  height: $scrollbar-size;
}

.scrollbar-sm::-webkit-scrollbar {
  width: $scrollbar-size-sm;
}

.scrollbar-sm::-webkit-scrollbar:horizontal {
  height: $scrollbar-size-sm;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*:hover::-webkit-scrollbar-track {
  background: $scrollbar-background;
}

*::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-color;
}

*:hover::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb-active-color;
}

//
// NO Scrollbar customization on document body
// Had to unset custom rules because :not(body)::*** doesn't work as it should
//
body,
.no-sgbs-custom-scrollbar {
  &::-webkit-scrollbar {
    width: $scrollbar-size-large;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-background;
  }

  &::-webkit-scrollbar-button {
    background-position: top;
    background-color: $scrollbar-background;
    height: 24px;
    /*&:decrement {
      background-image: url($scrollbar-hover-button-up);
    }
    &:increment {
      background-image: url($scrollbar-hover-button-down);
    }*/
  }

  &::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-active-color;

    // Border left/right tricks explained above...
    border-left: 3px solid $scrollbar-background;
    border-right: 3px solid $scrollbar-background;
  }
}

// firefox scrollbars customization
body *:not(.no-sgbs-custom-scrollbar) {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) transparent;

  &:hover {
    scrollbar-color: var(--primary) var(--bg-lvl3);
  }
}
