.shadow-xl {
  box-shadow: $box-shadow-xl !important;
}

.shadow-xxl {
  box-shadow: $box-shadow-xxl !important;
}

.shadow-max {
  box-shadow: $box-shadow-max !important;
}
