@import 'bootstrap/scss/popover';

$popover-padding-x: 0.75rem;
$popover-padding-y: 0.5rem;

.popover {
  min-width: $popover-min-width;
  color: $popover-color;
  font-family: unset;
  --#{$prefix}popover-font-size: 0.875rem;
  --#{$prefix}popover-border-radius: 0;
  --#{$prefix}popover-border-color: #{$border-color};
  --#{$prefix}popover-bg: #{$bg-lvl1};

  .list-group-item {
    padding: 0 20px 12px 0;
    border: none;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.popover-body {
  --#{$prefix}popover-body-padding-x: #{$popover-padding-x};
  --#{$prefix}popover-body-padding-y: #{$popover-padding-y};
  --#{$prefix}popover-body-color: #{$primary};
}

.popover-header {
  border-bottom: none;
  font-weight: $font-weight-bold;
  --#{$prefix}popover-header-bg: #{$bg-lvl1};
  --#{$prefix}popover-header-padding-x: #{$popover-padding-x};
  --#{$prefix}popover-header-padding-y: #{$popover-padding-y};
}
