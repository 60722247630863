@import 'bootstrap/scss/functions';

.display-5,
.h4,
h4,
.h5,
h5 {
  --#{$prefix}success-rgb: #{to-rgb(
      text-color-light-background('bg-lvl1', 'success')
    )};
  --#{$prefix}warning-rgb: #{to-rgb(
      text-color-light-background('bg-lvl1', 'warning')
    )};
}
