@import 'bootstrap/scss/close';

.btn-close {
  --#{$prefix}btn-focus-ring-color: #{$secondary};
  @include btn-flat-variant($secondary);
  padding: $btn-close-padding-y $btn-close-padding-x !important;
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
  @include btn-close-cross($secondary);
}
