@import 'bootstrap/scss/containers';

$grid-gutter-width-sm: 1rem;

@each $name, $value in $container-max-widths {
  .container-#{$name} {
    max-width: $value !important;
  }
}

.container, .container-fluid {
  padding-right: var(--#{$prefix}gutter-x);
  padding-left: var(--#{$prefix}gutter-x);
}

@include media-breakpoint-down(md) {
  .container, .container-fluid {
    --#{$prefix}gutter-x: #{$grid-gutter-width-sm};
  }
}

.container-sm {
  --#{$prefix}gutter-x: #{$grid-gutter-width-sm};
}
