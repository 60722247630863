@import 'bootstrap/scss/forms/form-control';

$textarea-padding-y-sm: px-to-rem(2) !default;
$textarea-padding-x-sm: px-to-rem(4) !default;
$textarea-padding-y-md: px-to-rem(6) !default;
$textarea-padding-x-md: px-to-rem(10) !default;
$textarea-padding-y-lg: px-to-rem(10) !default;
$textarea-padding-x-lg: px-to-rem(12) !default;
$textarea-padding-y-xl: px-to-rem(12) !default;
$textarea-padding-x-xl: px-to-rem(16) !default;

$input-disabled-opacity: 0.4 !default;
$input-disabled-box-shadow: inset 0 -0.0625rem 0 $input-disabled-border-color !default;

// SIZES
.form-control-xl {
  min-height: $input-height-xl;
  padding: $input-padding-y-xl $input-padding-x-xl;
  line-height: $input-line-height-xl;
  @include font-size($input-font-size-xl);
  @include border-radius($input-border-radius-xl);

  &::file-selector-button {
    padding: $input-padding-y-xl $input-padding-x-xl;
    margin: (-$input-padding-y-xl) (-$input-padding-x-xl);
    margin-inline-end: $input-padding-x-xl;
  }
}

.form-control-sm {
  line-height: $input-line-height-sm;
}
.form-control-lg {
  line-height: $input-line-height-xl;
}

.form-control {
  &:disabled,
  &[readonly],
  &.readonly {
    background-color: transparent;
    box-shadow: $input-disabled-box-shadow;
  }

  &:disabled {
    opacity: $input-disabled-opacity;
  }
  &:not(.form-control-sm):not(.form-control-lg):not(.form-control-xl) {
    min-height: $input-height !important;
  }

  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']):not([readonly]):not(.readonly) {
    box-shadow: inset 0 0 0 1px $input-btn-focus-color;
  }

  &[class^='is-'] {
    &:not(:hover) {
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }
}

// ALT STYLE
.form-control-outline {
  background: transparent;
  box-shadow: inset 0 0 0 1px $form-control-outline-border-color;
}

.form-control::placeholder {
  font-weight: $font-weight-light;
}

// TEXTAREAS
textarea.form-control {
  padding: $textarea-padding-y-md $textarea-padding-x-md;

  &.form-control-sm {
    padding: $textarea-padding-y-sm $textarea-padding-x-sm;
  }

  &.form-control-lg {
    padding: $textarea-padding-y-lg $textarea-padding-x-lg;
  }

  &.form-control-xl {
    padding: $textarea-padding-y-xl $textarea-padding-x-xl;
  }
}
