.badge-discreet-secondary {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'secondary')} !important;
}

.badge-discreet-info {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'info')} !important;
}

.badge-discreet-success {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'success')} !important;
}

.badge-discreet-warning {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'warning')} !important;
}

.badge-discreet-danger {
  --#{$prefix}badge-color: #{light-discreet-color('bg-lvl1', 'danger')} !important;
}