@import 'bootstrap/scss/forms/validation';

.was-validated {
  .form-select:valid,
  .form-control:valid {
    &:not(:disabled):not(:focus-visible):not(:focus) {
      box-shadow: inset 0 -1px 0 0 $form-feedback-valid-color !important;
    }
    &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
      box-shadow: inset 0 0 0 1px $form-feedback-valid-color !important;
    }
  }

  .form-select:invalid,
  .form-control:invalid {
    &:not(:disabled):not(:focus-visible):not(:focus) {
      box-shadow: inset 0 -1px 0 0 $form-feedback-invalid-color !important;
    }
    &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
      box-shadow: inset 0 0 0 1px $form-feedback-invalid-color !important;
    }
  }
}

.form-select.is-valid,
.form-control.is-valid {
  &:not(:disabled):not(:focus-visible):not(:focus) {
    box-shadow: inset 0 -1px 0 0 $form-feedback-valid-color !important;
  }
  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
    box-shadow: inset 0 0 0 1px $form-feedback-valid-color !important;
  }
}

.form-select.is-invalid,
.form-control.is-invalid {
  &:not(:disabled):not(:focus-visible):not(:focus) {
    box-shadow: inset 0 -1px 0 0 $form-feedback-invalid-color !important;
  }
  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
    box-shadow: inset 0 0 0 1px $form-feedback-invalid-color !important;
  }
}
.form-select.is-warning,
.form-control.is-warning {
  &:not(:disabled):not(:focus-visible):not(:focus) {
    box-shadow: inset 0 -1px 0 0 $form-feedback-warning-color !important;
  }
  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
    box-shadow: inset 0 0 0 1px $form-feedback-warning-color !important;
  }
}
.form-select.is-edited,
.form-control.is-edited {
  &:not(:disabled):not(:focus-visible):not(:focus) {
    box-shadow: inset 0 -1px 0 0 $form-feedback-edited-color !important;
  }
  &:hover:not(:disabled):not(:focus-visible):not(:focus):not([class^='is-']) {
    box-shadow: inset 0 0 0 1px $form-feedback-edited-color !important;
  }
}

.is-edited ~ .edited-feedback,
.is-warning ~ .warning-feedback {
  display: block !important;
}

.edited-feedback {
  color: $form-feedback-edited-color !important;
}

.warning-feedback {
  color: $form-feedback-warning-color !important;
}

.invalid-feedback,
.valid-feedback,
.edited-feedback,
.warning-feedback {
  .icon {
    font-size: unset;
  }
}
