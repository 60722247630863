@use "sass:math";

$progress-molecule-item-size: 1.5rem;
$progress-molecule-item-border-width: 2px;
$progress-molecule-label-font-weight: $font-weight-medium !default;
$progress-molecule-label-font-size: $font-size-base !default;
$progress-icon-color: $primary-alt !default;

.stepper {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.stepper-step {
  flex: 2;
  position: relative;

  &.inactive {
    color: $progress-molecule-inactive-color;

    .stepper-label {
      color: $progress-molecule-inactive-label-color !important;
    }
  }

  &.blocked {
    .stepper-step-inner::after {
      color: $progress-molecule-inactive-color;
    }

    &.muted {
      .stepper-step-inner::before {
        color: $primary;
      }
    }
  }
}

.stepper-step-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: $progress-molecule-item-border-width;
    background: currentColor;
  }
}

.stepper-label {
  font-weight: $progress-molecule-label-font-weight;
  margin-top: 0.5rem;
  font-size: $progress-molecule-label-font-size;
  display: block;

  .stepper:not(.stepper-vertical) & {
    text-align: center;
  }
}

.stepper-indicator {
  width: $progress-molecule-item-size;
  height: $progress-molecule-item-size;
  border-radius: 50%;
  border: $progress-molecule-item-border-width solid currentColor;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .fill & {
    background: currentColor;
  }
}

.stepper-no-indicator {
  height: $progress-molecule-item-size;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Exceptions */

.stepper-step:not(.current) .icon {
  color: $progress-icon-color;
  height: 0.8rem;
  line-height: 0.8rem;
}

button.stepper-indicator {
  cursor: pointer;
  padding: 0;

  @each $color, $value in $theme-colors {
    .text-#{$color} & {
      color: $value;
    }
  }
}

.stepper .stepper-step {
  &:first-child {
    flex: 1;
    .stepper-step-inner::before {
      display: none;
    }
    .stepper-label {
      text-align: left;
    }
  }

  &:last-child {
    flex: 1;
    .stepper-step-inner::after {
      display: none;
    }
    .stepper-label {
      text-align: right;
    }
  }
}

.stepper.stepper-vertical .stepper-step:last-child .stepper-label {
  text-align: left;
}

/* Vertical */
.stepper-vertical {
  height: 100%;
  flex-direction: column;

  .stepper-step-inner {
    flex-direction: column;
    height: 100%;
  }

  .stepper-step-inner {
    flex-direction: column;
    height: 100%;

    &::before,
    &::after {
      content: '';
      width: $progress-molecule-item-border-width;
      height: 100%;
    }
  }

  .stepper-label {
    position: absolute;
    margin-top: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: 2 * $progress-molecule-item-size;
    white-space: nowrap;
  }

  .stepper-step {
    &:first-child {
      .stepper-label {
        top: 0;
        bottom: auto;
      }
    }
    &:last-child {
      .stepper-label {
        bottom: 0;
        top: auto;
      }
    }
  }
}
