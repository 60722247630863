@import 'bootstrap/scss/toasts';

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    .toast-header {
      color: $value;
    }
  }
}

.toast {
  --#{$prefix}toast-border-color: #{$border-color};
}
.toast-header {
  background-color: var(--#{$prefix}toast-bg);
  --#{$prefix}toast-header-border-color: #{$hr-border-color};
  &::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;
    display: inline-block;
    background-color: currentColor;
  }

  .btn-close {
    padding: 0 !important;
    margin-right: 0;
    height: px-to-rem(14);
    width: px-to-rem(14);
  }
}

.toast-footer {
  --#{$prefix}toast-header-border-color: #{$hr-border-color};
  border-top: var(--#{$prefix}toast-border-width) solid
    var(--#{$prefix}toast-header-border-color);
  display: flex;
  align-items: center;

  .btn {
    flex: 1;
    border: none;

    & + .btn {
      border-left: 1px solid var(--#{$prefix}toast-header-border-color);
    }
  }
}

.notification {
  @extend .toast;
}

.notification-header {
  @extend .toast-header;
}

.notification-timestamp {
  color: $secondary !important;
}

.notification-body {
  @extend .toast-body;
}

.notification-footer {
  @extend .toast-footer;
}

@each $color, $value in $theme-colors {
  .notification-#{$color} {
    @extend .toast-#{$color};
  }
}
