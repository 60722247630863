@import 'bootstrap/scss/reboot';

a.text-link {
  color: $primary;

  // inspired by: https://css-tricks.com/almanac/properties/t/text-underline-position/
  // support: https://caniuse.com/#search=text-underline-position
  text-underline-position: under;
  text-decoration: underline;

  &:hover:not(:disabled):not(.disabled) {
    background-color: get-interaction-color("hover");
  }

  &:not(:disabled):not(.disabled):active {
    background-color: get-interaction-color("onclick");
  }
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

ol, ul, dl {
  margin-bottom: 0;
}
