$icon-cursor: default !default;
$icons-size-xs: px-to-rem(16) !default;
$icons-size-sm: px-to-rem(18) !default;
$icons-size-md: px-to-rem(24) !default;
$icons-size-lg: px-to-rem(32) !default;
$icons-size-xl: px-to-rem(48) !default;

.icon {
  font-size: $icons-size-md;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;

  cursor: $icon-cursor;
  user-select: none;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  // HACK(@fzibi030518): invisible rotation visually fix pixellised iconfont when size is too small
  transform: rotate(0.03deg);

  > svg {
    fill: currentColor;
    height: 1em;
    width: 1em;
    margin-top: -0.5em;
  }
}

button .icon,
a .icon {
  cursor: pointer;
  line-height: 1;
}

//
// Sizing
//

.icon-xs {
  font-size: $icons-size-xs !important;
}

.icon-sm {
  font-size: $icons-size-sm !important;
}

.icon-md {
  font-size: $icons-size-md !important;
}

.icon-lg {
  font-size: $icons-size-lg !important;
}

.icon-xl {
  font-size: $icons-size-xl !important;
}
