$footer-light-text-color: $secondary;
$footer-dark-text-color: map-get($palette-dark-1, 'secondary');
$footer-black-text-color: map-get($palette-dark-4, 'secondary');

@mixin footer($bgColor, $textColor) {
  color: $textColor;
  background-color: $bgColor;

  .list-unstyled .h5 {
    font-size: 1.125rem;
  }

  .list-unstyled a.h5 {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  .sgbs-footer-item {
    border: none;
    padding: 0;
    color: $textColor;
    transition: color 0.3s;

    &:not(span):hover {
      color: $textColor;
    }
  }
}

.sgbs-footer-light {
  @include footer(transparent, $footer-light-text-color);
}

.sgbs-footer-dark {
  @include footer($sg-dark-bg-1, $footer-dark-text-color);
}

.sgbs-footer-black {
  @include footer($sg-dark-bg-4, $footer-black-text-color);
}
