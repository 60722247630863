@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $index, $space in $spacers {
      .gap#{$infix}-#{$index} {
        gap: $space !important;
      }
      .gap-y#{$infix}-#{$index} {
        row-gap: $space !important;
      }
      .gap-x#{$infix}-#{$index} {
        column-gap: $space !important;
      }
    }
  }
}
