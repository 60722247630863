@import 'bootstrap/scss/progress';

$progress-height-small: 0.125rem !default;
$progress-height-large: 1.25rem !default;

.progress {
  overflow: visible; // TO ALLOW PROGRESS VALUE TO BE OUTSIDE THE BAR
}

.progress-sm {
  --#{$prefix}progress-height: #{$progress-height-small};
}

.progress-lg {
  --#{$prefix}progress-height: #{$progress-height-large};
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    $primary-alt 25%,
    $progress-bg 25%,
    $progress-bg 50%,
    $primary-alt 50%,
    $primary-alt 75%,
    $progress-bg 75%,
    $progress-bg
  );
}

.progress-infinite {
  position: relative;
  overflow: hidden;
}

.progress-bar-infinite {
  position: absolute;
  bottom: 0;
  top: 0;   
  width: 200%;
  animation: animated-progress-bar 2s infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.4, 1);
}

.progress-bar {
  text-align: right;
  overflow: visible;
}

.progress-bar-label {
  padding-right: map-get($spacers, 2);
}

.progress-bar-label-over {
  color: $primary;
  margin-top: -2rem;
}
