@import 'bootstrap/scss/tooltip';

.tooltip {
  --#{$prefix}tooltip-font-size: 14px;
  --#{$prefix}tooltip-padding-x: 0.5rem;
  --#{$prefix}tooltip-bg: #{$primary};
  --#{$prefix}tooltip-color: #{$primary-alt};
}

@each $color, $value in $theme-colors {
  .tooltip-#{$color} {
    .tooltip-inner {
      --#{$prefix}tooltip-bg: #{$value};
    }
  }

  .tooltip-#{$color} .bs-tooltip-auto,
  .tooltip-#{$color}.bs-tooltip-auto {
    .tooltip-arrow {
      &::before {
        --#{$prefix}tooltip-bg: #{$value};
      }
    }
  }
}
