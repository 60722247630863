// NEW BTN COLLAPSE
@mixin collapse-icon($icon, $size) {
  content: $icon;
  font-family: 'Material Icons';
  font-size: $size;
  line-height: $size;
  height: $size;
  color: $secondary;
}

@mixin collapse-aria-expanded($size: $btn-icon-size, $padding-y: $btn-padding-y) {
  &[aria-expanded="true"] {
    &.collapse-icon-start, &.btn-icon {
      &::before {
        @include collapse-icon('keyboard_arrow_up', $size);
      }
      &.collapse-right {
        &::before {
          @include collapse-icon('keyboard_arrow_down', $size);
        }
      }
    }
    &.collapse-icon-start:not(.collapse-fill-center) {
      padding-left: $padding-y !important;
    }

    &.collapse-icon-end {
      &::after {
        @include collapse-icon('keyboard_arrow_up', $size);
      }
      &.collapse-right {
        &::after {
          @include collapse-icon('keyboard_arrow_down', $size);
        }
      }
    }
    &.collapse-icon-end:not(.collapse-fill-center) {
      padding-right: $padding-y !important;
    }
  }
  &[aria-expanded="false"] {
    &.collapse-icon-start, &.btn-icon {
      &::before {
        @include collapse-icon('keyboard_arrow_down', $size);
      }
      &.collapse-right {
        &::before {
          @include collapse-icon('keyboard_arrow_right', $size);
        }
      }
    }
    &.collapse-icon-start:not(.collapse-fill-center) {
      padding-left: $padding-y !important;
    }

    &.collapse-icon-end {
      &::after {
        @include collapse-icon('keyboard_arrow_down', $size);
      }
      &.collapse-right {
        &::after {
          @include collapse-icon('keyboard_arrow_right', $size);
        }
      }
    }
    &.collapse-icon-end:not(.collapse-fill-center) {
      padding-right: $padding-y !important;
    }
  }
}

.btn-collapse {
  --#{$prefix}btn-color: #{$primary};
  --#{$prefix}btn-hover-color: #{$primary};
  --#{$prefix}btn-active-color: #{$primary} !important;
  gap: $btn-gap;

  // Fill
  &.collapse-fill, &.collapse-fill-center {
    width: 100%;
  }
  &.collapse-fill {
    justify-content: space-between;
  }

  @include collapse-aria-expanded();

  // Sizing
  &.btn-sm {
    gap: $btn-gap-sm !important;
    @include collapse-aria-expanded($btn-icon-size-sm, $btn-padding-y-sm);
  }

  &.btn-lg {
    gap: $btn-gap-lg !important;
    @include collapse-aria-expanded($btn-icon-size-lg, $btn-padding-y-lg);
  }

  &.btn-xl {
    gap: $btn-gap-xl !important;
    @include collapse-aria-expanded($btn-icon-size-xl, $btn-padding-y-xl);
  }
}

// Divider
.collapse-divider {
  display: flex;
  flex-direction: row;
  .collapse-fill-center {
    width: auto;
  }
  &::before, &::after {
    content: "";
    width: 100%;
    margin: auto;
    border-bottom: 1px solid $border-color;
  }
}

.collapse-divider:has(:not(.collapse-fill-center)) {
  &::before {
    width: auto;
    margin: unset;
    border-bottom: none;
  }
}



// Accordion only
.card-header [data-bs-toggle='collapse'] {
  // Turn arrow to the bottom when accordion level is open
  &:not(.collapsed) .icon {
    transform: rotate(90deg);
  }

  &.btn {
    &:hover,
    &:focus,
    &:active {
      background: transparent;
    }
  }
}

// SEPARATORS
// Component introduced by SG UX/UI team
// cf https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap/issues/51#issuecomment-157287
.collapse-separator {
  .card {
    background: transparent;
  }

  .card-header [data-bs-toggle='collapse'] {
    position: relative;
    border-top: 1px solid $border-color;

    &.collapsed span {
      color: $secondary;
    }

    &:not(.collapsed) .icon {
      transform: rotate(180deg);
      transform-origin: 50% 46%;
    }

    span {
      z-index: 1;
      padding: 0.5rem;
      color: $primary;
      white-space: nowrap;
    }
  }

  &.collapse-separator-centered {
    .card-header [data-bs-toggle='collapse'] {
      border-top: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::after,
      &::before {
        content: '';
        border-top: 1px solid $border-color;
        height: 2px;
        width: 100%;
        display: block;
      }
    }

    span {
      padding: 0.5rem;
      color: $primary;
    }
  }
}

// HTML details summary solution
details {
  summary {
    .btn:hover {
      background: transparent !important;
    }

    &::before {
      content: 'keyboard_arrow_right';
      font-family: 'Material Icons', sans-serif;
      font-size: 1.125rem;
      line-height: 1;
      margin-right: 0.5rem;
      color: $secondary;
    }
  }

  &[open] {
    summary {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}
