@import 'bootstrap/scss/buttons';

$btn-size-sm: px-to-rem(20);
$btn-size-md: px-to-rem(32);
$btn-size-lg: px-to-rem(40);
$btn-size-xl: px-to-rem(48);

.btn {
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-focus-box-shadow: $input-btn-focus-box-shadow;
  --#{$prefix}btn-hover-color: #{$btn-color};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-disabled-border-color: transparent;
  --#{$prefix}btn-active-color: #{$primary-alt} !important;
  --#{$prefix}btn-disabled-color: #{$primary-alt} !important;

  &.btn-primary-alt {
    &:disabled {
      color: unset;
    }
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  .btn-check:focus + &,
  &:focus {
    box-shadow: none !important;
  }

  &.btn-block {
    width: 100%;
  }
  &.btn-icon {
    height: $btn-size-md;
    width: $btn-size-md;

    .icon {
      font-size: $btn-icon-size;
      line-height: $btn-icon-size;
      height: $btn-icon-size;
    }
  }
}

.btn-icon {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-sm {
  line-height: $btn-line-height-sm;

  &.btn-icon {
    height: $btn-size-sm;
    width: $btn-size-sm;

    .icon {
      font-size: $btn-icon-size-sm;
      line-height: $btn-icon-size-sm;
      height: $btn-icon-size-sm;
    }
  }
}

.btn-lg {
  line-height: $btn-line-height-lg;

  &.btn-icon {
    height: $btn-size-lg;
    width: $btn-size-lg;

    .icon {
      font-size: $btn-icon-size-lg;
      line-height: $btn-icon-size-lg;
      height: $btn-icon-size-lg;
    }
  }
}

.btn-xl {
  line-height: $btn-line-height-xl;

  &.btn-icon {
    height: $btn-size-xl;
    width: $btn-size-xl;

    .icon {
      font-size: $btn-icon-size-xl;
      line-height: $btn-icon-size-xl;
      height: $btn-icon-size-xl;
    }
  }
}

//
// Alternate buttons
//

@each $name, $color in $theme-colors {
  .btn.btn-#{$name} {
    --#{$prefix}btn-color: #{$btn-color} !important;
    --#{$prefix}btn-hover-color: #{$btn-color} !important;
    --#{$prefix}btn-bg: #{$color} !important;
    --#{$prefix}btn-border-color: #{$color} !important;
    --#{$prefix}btn-hover-bg: #{button-hover-background($color)} !important;
    --#{$prefix}btn-hover-border-color: #{button-hover-background(
        $color
      )} !important;

    @include button-variant($color, $color);
  }
}

.btn-xl {
  @include button-size(
    $btn-padding-y-xl,
    $btn-padding-x-xl,
    $btn-font-size-xl,
    $btn-border-radius-xl
  );
}

//////////////////////////////////////////////
//////////////////////////////////////////////

.btn.btn-xl {
  > .icon {
    margin-top: -1.5px;
    margin-bottom: -1.5px;
  }
}

.btn {
  @include btn-icon-text-sizing($btn-icon-size, $btn-gap, $btn-padding-y);
}
.btn.btn-sm {
  @include btn-icon-text-sizing(
    $btn-icon-size-sm,
    $btn-gap-sm,
    $btn-padding-y-sm
  );
}
.btn.btn-lg {
  @include btn-icon-text-sizing(
    $btn-icon-size-lg,
    $btn-gap-lg,
    $btn-padding-y-lg
  );
}
.btn.btn-xl {
  @include btn-icon-text-sizing(
    $btn-icon-size-xl,
    $btn-gap-xl,
    $btn-padding-y-xl
  );
}
// end of ICON IN BUTTONS

// Default button adapted xxxx
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} .btn.btn-#{$name} {
      background-color: $adapted_color;
      border-color: $adapted_color;

      &:hover {
        background-color: button-hover-background($adapted_color);
        border-color: button-hover-background($adapted_color);
      }

      &:focus,
      &.focus {
        color: $btn-color;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($btn-color);
        background-color: $adapted_color !important;
        border-color: $adapted_color !important;
      }
    }
  }
}

@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      .btn.btn-success {
        $adapted_success: map-get(map-get($adaptive-palette, $background), 'success');
        background-color: button-darken-background($adapted_success, $success-percent-reduction);
        border-color: button-darken-background($adapted_success, $success-percent-reduction);

        &:hover {
          background-color: button-hover-background($adapted_success, 9%) !important;
          border-color: button-hover-background($adapted_success, 9%) !important;
        }
      }

      .btn.btn-warning {
        $adapted_warning: map-get(map-get($adaptive-palette, $background), 'warning');
        background-color: button-darken-background($adapted_warning, $warning-percent-reduction);
        border-color: button-darken-background($adapted_warning, $warning-percent-reduction);

        &:hover {
          background-color: button-hover-background($adapted_warning, 10%) !important;
          border-color: button-hover-background($adapted_warning, 10%) !important;
        }
      }
    }
  }
}

// Discreet button adapted
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} .btn.btn-discreet-#{$name} {
      color: $adapted_color;

      &:hover:not(:disabled):not(.disabled) {
        background-color: $adapted_color;
        border-color: $adapted_color;
      }

      &:not(:disabled):not(.disabled) {
        &:focus,
        &.focus {
          background-color: $adapted_color;
        }
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($adapted_color);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
}

// Discreet Button over light backgrounds
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    .#{$background} .btn.btn-discreet-#{$name} {
      &:focus,
      &.focus {
        color: $white !important;
      }

      $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

      &:hover:not(:disabled):not(.disabled) {
        color: $white;
        background-color: button-darken-background($adapted_color);
        border-color: button-darken-background($adapted_color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($white);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
  .#{$background} {
    .btn.btn-discreet-secondary {
      color: light-discreet-color($background, 'secondary');
    }
    .btn.btn-discreet-info {
      color: light-discreet-color($background, 'info');
    }
    .btn.btn-discreet-success {
      color: light-discreet-color($background, 'success');
    }
    .btn.btn-discreet-warning {
      color: light-discreet-color($background, 'warning');
    }
    .btn.btn-discreet-danger {
      color: light-discreet-color($background, 'danger');
    }
  }
}

// Discreet Button over dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    .#{$background} .btn.btn-discreet-#{$name} {
      $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

      &:focus,
      &.focus {
        color: #000 !important;
      }

      &:hover:not(:disabled):not(.disabled) {
        color: #000;
        background-color: button-lighten-background($adapted_color);
        border-color: button-lighten-background($adapted_color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color(#000);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
  .#{$background} {
    .btn.btn-discreet-secondary {
      color: dark-discreet-color($background, 'secondary');
    }

    .btn.btn-discreet-info {
      color: info-discreet-color-on-dark($background);
    }
    .btn.btn-discreet-danger {
      color: discreet-danger-text-color-dark-background($background);
    }
  }
}

// On dark backgrounds
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    .#{$background} .btn.btn-#{$name} {
      color: #000;

      &:hover {
        background-color: button-lighten-background($color);
        border-color: button-lighten-background($color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color(#000);
      }
    }
  }
}

// On light backgrounds
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    .#{$background} .btn.btn-#{$name} {
      color: $white;

      &:hover {
        background-color: button-darken-background($color);
        border-color: button-darken-background($color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($white);
      }
    }
  }
}

//
// BUTTON LINK
//

.btn-link {
  --#{$prefix}btn-color: #{$link-color};
  --#{$prefix}btn-hover-color: #{$link-hover-color};
  --#{$prefix}btn-font-weight: #{$font-weight-medium};
  --#{$prefix}btn-disabled-color: #{$link-color} !important;

  &:hover,
  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:active {
    --#{$prefix}btn-active-color: #{$link-hover-color} !important;
  }
}

// Button link adapted
@each $background in $adaptive-backgrounds {
  $adapted_color: map-get(map-get($adaptive-palette, $background), 'secondary');
  .#{$background} .btn.btn-link {
    --#{$prefix}btn-color: #{$adapted_color};
  }
}

// Button link over Light background
@each $background in $adaptive-backgrounds-light {
  $adapted_color: map-get(map-get($adaptive-palette, $background), 'secondary');

  .#{$background} .btn.btn-link {
    &:hover:not(:disabled):not(.disabled) {
      color: black;
    }

    &:focus,
    &.focus {
      color: black;
    }
  }
}

// Button link over Dark background
@each $background in $adaptive-backgrounds-dark {
  $adapted_color: map-get(map-get($adaptive-palette, $background), 'secondary');

  .#{$background} .btn.btn-link {
    &:hover:not(:disabled):not(.disabled) {
      color: white;
    }

    &:focus,
    &.focus {
      color: white;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

// Add custom theme color buttons
// Inspired by https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L59-L73

.btn {
  &.btn-flat {
    @include btn-flat-variant($primary);
    &:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
      color: $secondary;
    }
  }
}

@each $name, $values in $theme-colors-with-lighter-pair {
  $color: nth($values, 1);
  $lighter-color: nth($values, 2);

  //

  .btn {
    &.btn-flat-#{$name} {
      @include btn-flat-variant($color);
    }

    &.btn-discreet-#{$name} {
      @include button-discreet-variant($color);
    }

    &.btn-outline-#{$name} {
      @include btn-outline-variant($color);
    }
  }
}

// SECONDARY PALETTE
@each $name, $color in $secondary-colors {
  .btn-#{$name} {
    @include custom-button-variant(
      $color,
      lighten($color, $lighten-color-ratio)
    );
  }

  .btn-flat-#{$name} {
    --#{$prefix}btn-focus-ring-color: #{$color};
    @include btn-flat-variant($color);
  }

  .btn-discreet-#{$name} {
    @include button-discreet-variant($color);
  }

  .btn-outline-#{$name} {
    @include btn-outline-variant($color);
  }
}

.btn.btn-default {
  --#{$prefix}btn-color: #{$primary};
  --#{$prefix}btn-disabled-color: #{$primary} !important;
  --#{$prefix}btn-active-color: #{$primary};
  --#{$prefix}btn-bg: transparent;

  --#{$prefix}btn-hover-bg: #{get-interaction-color("hover")};
  --#{$prefix}btn-hover-color: #{$primary};

  --#{$prefix}btn-active-color: #{$primary} !important;
  --#{$prefix}btn-active-bg: #{get-interaction-color("onclick")};

  --#{$prefix}btn-focus-color: #{$primary} !important;
  --#{$prefix}btn-focus-bg: transparent;

  &:focus,
  &:focus-visible {
    --#{$prefix}btn-hover-bg: transparent !important;
  }

  &,
  &:active {
    box-shadow: inset 0 0 0 1px $btn-default-border-color !important;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 2px #{$primary} !important;
  }
}

.btn {
  &:disabled,
  &.disabled {
    .icon {
      cursor: default;
    }
  }
}

//
// OUTLINE
//

// Outline button adapted
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      .btn.btn-outline-#{$name} {
        border-color: $adapted_color;
        color: $adapted_color;
      }
    }
  }
}

// Outline button over Light background
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} .btn.btn-outline-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        color: $white;
        background-color: button-darken-background($adapted_color);
        border-color: button-darken-background($adapted_color);
      }

      &:focus,
      &.focus {
        background: $adapted_color;
        color: $white;
      }
    }

    .#{$background} {
      @if($name == 'success') {
        .btn.btn-outline-success {
          $adapted_success: map-get(map-get($adaptive-palette, $background), 'success');
          color: darken($adapted_success, $success-percent-reduction);
          &:hover:not(:disabled):not(.disabled) {
            background-color: button-darken-background($adapted_success, $success-percent-reduction);
            border-color: button-darken-background($adapted_success, $success-percent-reduction);
          }
        }
      }

      @if($name == 'warning') {
        .btn.btn-outline-warning {
          $adapted_warning: map-get(map-get($adaptive-palette, $background), 'warning');
          color: darken($adapted_warning, $warning-percent-reduction);
          &:hover:not(:disabled):not(.disabled) {
            background-color: button-darken-background($adapted_warning, $warning-percent-reduction);
            border-color: button-darken-background($adapted_warning, $warning-percent-reduction);
          }
        }
      }
    }
  }
}

// Outline button over Dark background
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} .btn.btn-outline-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        color: #000;
        background-color: button-lighten-background($adapted_color);
        border-color: button-lighten-background($adapted_color);
      }

      &:focus,
      &.focus {
        background: $adapted_color;
        color: #000;
      }
    }
  }
}

//
// FLAT
//

// Flat button adapted
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} {
      > .btn.btn-flat-#{$name} {
        color: $adapted_color;
      }
    }
  }
}

// Flat button over Light background
@each $background in $adaptive-backgrounds-light {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} .btn.btn-flat-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        background-color: get-interaction-color("hover", $adapted_color);
      }
    }
    .#{$background} .btn.btn-flat-#{$name}.active {
      &:hover:not(:disabled):not(.disabled) {
        background-color: get-interaction-color("selected_hover", $adapted_color);
      }
    }

    .#{$background} {
      @if($name == 'success') {
        .btn.btn-flat-success {
          $adapted_success: map-get(map-get($adaptive-palette, $background), 'success');
          color: darken($adapted_success, $success-percent-reduction);
        }
      }

      @if($name == 'warning') {
        .btn.btn-flat-warning {
          $adapted_warning: map-get(map-get($adaptive-palette, $background), 'warning');
          color: darken($adapted_warning, $warning-percent-reduction);
        }
      }
    }
  }
}

// Flat button over Dark background
@each $background in $adaptive-backgrounds-dark {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);

    .#{$background} .btn.btn-flat-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        background-color: get-interaction-color("hover", $adapted_color);
      }
    }
    .#{$background} .btn.btn-flat-#{$name}.active {
      &:hover:not(:disabled):not(.disabled) {
        background-color: get-interaction-color("selected_hover", $adapted_color);
      }
    }
  }
}

//
// EXCEPTIONS
//

.btn.btn-primary:not(:disabled):not(.disabled) {
  &:hover {
    background-color: button-primary-hover-background();
    border-color: button-primary-hover-background();
  }
}

.btn.btn-outline-primary-alt {
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).hover {
    color: $primary !important;
  }
}

.btn.btn-toggle-primary-alt.active {
  color: $primary !important;
}

.btn.btn-primary-alt {
  --#{$prefix}btn-color: #{$primary} !important;
  --#{$prefix}btn-disabled-color: #{$primary} !important;
  --#{$prefix}btn-hover-color: #{$primary} !important;
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: button-primary-alt-active-background() !important;
  }
}

.btn {
  &.btn-dark,
  &.btn-black {
    color: $white !important;

    &:hover {
      color: $white !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color($white);
    }
  }

  &.btn-white,
  &.btn-light {
    color: #000 !important;

    &:hover {
      color: #000 !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color(#000);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color(#000);
    }
  }
}

.btn.btn-discreet-primary-alt {
  &:not(:disabled):not(.disabled) {
    &:hover, &:active, &:focus {
      color: $primary;
    }
  }
}

.btn.btn-outline-primary-alt {
  &:not(:disabled):not(.disabled) {
    &:focus {
      color: $primary;
    }
  }
}
