@import 'bootstrap/scss/modal';

$modal-content-max-height: 90vh !default;


// Responsive modal paddings
// default is 1rem, set in Bootstrap variables

$modal-paddings: (
  sm: 1.5rem,
  lg: 2rem
);

@each $breakpoint, $value in $modal-paddings {
  @include media-breakpoint-up($breakpoint) {
    .modal {
      --#{$prefix}modal-header-padding: #{$value};
      --#{$prefix}modal-padding: #{$value};
    }
  }
}

// Vertically aligned by default
.modal:not(.modal-top) .modal-dialog {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
  .modal-content {
    max-height: 100vh;
    height: 100vh;
  }
}

.modal-body {
  overflow-y: auto;
  padding-top: 0;
}

.modal-content {
  max-height: 100vh;
  border: none;
  .btn-close {
    opacity: 1;
    margin: calc(-1.5 * var(--#{$prefix}modal-header-padding-y)) calc(-0.75 * var(--#{$prefix}modal-header-padding-x)) calc(-0.5 * var(--#{$prefix}modal-header-padding-y)) auto;
    &:hover, &:focus-visible {
      background-color: var(--#{$prefix}btn-hover-bg);
    }
    &:active {
      background-color: var(--#{$prefix}btn-active-bg);
    }
    &:focus-visible {
      box-shadow: inset 0 0 0 0.125rem var(--#{$prefix}btn-focus-ring-color) !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal-content {
    max-height: 90vh;
    margin: 24px;
  }
}

.modal-fullscreen {
  .modal-dialog {
    max-width: none;
    width: 100vw;
    height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    max-height: none;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
