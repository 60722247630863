@mixin make-grid-columns-custom-aliases(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Create gutter-* alias for g-* classes
      @each $key, $value in $gutters {
        .gutter#{$infix}-#{$key},
        .gutter-x#{$infix}-#{$key} {
          --#{$prefix}gutter-x: #{$value};
        }

        .gutter#{$infix}-#{$key},
        .gutter-y#{$infix}-#{$key} {
          --#{$prefix}gutter-y: #{$value};
        }
      }
    }
  }
}
