// CARD

@mixin card-states($color: $primary) {
  --#{$prefix}card-color: #{$color};
  border-width: 0;
  will-change: background-color;
  transition: background-color 0.3s;

  &.card-bordered {
    outline: $border-width solid $color;
  }

  &.card-raised {
    --#{$prefix}card-box-shadow: #{$box-shadow-sm};
  }

  // Filling
  &.card-filling {
    @include card-state-filling($color);
  }

  // Bordering
  &.card-bordering {
    @include card-state-bordering($color);
  }
}

@mixin card-state-filling($color: $primary) {
  &:hover {
    --#{$prefix}card-color: #{$color};
    --#{$prefix}card-bg: #{rgba($color, $card-hover-bg-opacity)};
  }
  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active {
    --#{$prefix}card-color: #{$color};
    --#{$prefix}card-bg: #{rgba($color, $card-active-bg-opacity)};
  }
}

@mixin card-state-bordering($color: $primary) {
  &:hover {
    --#{$prefix}card-color: #{$color};
    outline: $border-width solid $color;
  }
  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active {
    --#{$prefix}card-color: #{$color};
    outline: 2px solid $color;
  }
}

@mixin card-state-raising() {
  will-change: box-shadow;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: $box-shadow !important;
  }
  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active,
  &:not(.disabled):not(:disabled):focus,
  &:not(.disabled):not(:disabled).focus {
    box-shadow: $box-shadow-max;
  }
}

// BUTTON

@mixin button-in-card-states($color) {
  &:hover:not(:disabled):not(.disabled) {
    color: $primary-alt;
    background-color: button-hover-background($color);
    border-color: button-hover-background($color);
  }

  &:focus,
  &.focus {
    color: $primary-alt;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
    background-color: $color;
    border-color: $color;
  }
}
