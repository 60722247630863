
//
// Extra flex utility classes
// Inspired by https://github.com/twbs/bootstrap/blob/v4.1.0/scss/utilities/_flex.scss
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex-basis#{$infix}-#{100} {
      flex-basis: 100% !important;
    }
  }
}

.flex-center, .flex-center-x {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-center-y {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}

.flex-between {
  display: flex !important;
  justify-content: space-between !important;

  &.flex-column {
    justify-content: center !important;
    align-items: space-between !important;
  }
  align-items: center !important;
}

.flex-x {
  display: flex !important;
  align-items: center !important;
}

.flex-y {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.flex-between-x {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.flex-between-y {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: column !important;
}
