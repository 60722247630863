@import 'bootstrap/scss/breadcrumb';

.breadcrumb {
  --#{$prefix}breadcrumb-padding-x: 0;
  --#{$prefix}breadcrumb-padding-y: 0;
  --#{$prefix}breadcrumb-item-padding-x: 12px;

  column-gap: 12px;
  row-gap: 4px;
}
.breadcrumb-item {
  a {
    color: $link-color;
    font-weight: $font-weight-medium;
  
    &:hover {
      color: $link-hover-color;
    }
  }
  + .breadcrumb-item {
    padding-left: 0;
  }
}
