$utility-border-width: 1px !default;

$borders: (
  xl: 0.375rem,
  lg: 0.1875rem,
  md: 0.0625rem,
  sm: 0.03125rem,
) !default;

.border {
  --#{$prefix}border-color: #{$border-color} !important;
  --#{$prefix}border-style: solid !important;
  --#{$prefix}border-width: #{$border-width};
}

$border-orientations: (
  'top': 'top',
  'end': 'right',
  'bottom': 'bottom',
  'start': 'left',
);
@each $name, $direction in $border-orientations {
  .border-#{$name} {
    --#{$prefix}border-opacity: 1;
    --#{$prefix}border-style: solid !important;
    --#{$prefix}border-width: #{$border-width};
    --#{$prefix}border-color: #{rgba($border-color, var(--#{$prefix}border-opacity))} !important;
  }
}

$border-sizes: 'sm' 'md' 'lg' 'xl';
@each $borderSize in $border-sizes {
  .border-#{$borderSize} {
    --#{$prefix}border-width: #{map-get($borders, $borderSize)} !important;
  }
}

// BORDER COLORS

@each $name, $color in $theme-colors {
  .border-#{$name} {
    --#{$prefix}border-color: #{$color} !important;
  }
}

// Secondary palette
@each $name, $color in $secondary-colors {
  .border-#{$name} {
    --#{$prefix}border-color: #{$color} !important;
  }
}

// BORDER OPACITY

@mixin set-border-opacity-rules() {
  @each $opacity in map-keys($opacities) {
    .border {
      &.border-opacity-#{$opacity} {
        --#{$prefix}border-color: #{rgba(
            $border-color,
            map-get($opacities, $opacity)
          )} !important;
      }
    }
    .border-opacity-#{$opacity} {
      --#{$prefix}border-opacity: #{map-get($opacities, $opacity)} !important;
    }
  }
}

@include set-border-opacity-rules();
