@import 'bootstrap/scss/alert';

.alert-link {
  text-decoration: underline;

  &:hover:not(:disabled):not(.disabled) {
    background-color: rgba($primary, 0.08);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: rgba($primary, 0.15);
  }
}

@each $name, $color in $theme-colors {
  @if ($name != 'primary-alt' and $name != 'light') {
    .alert-#{$name} {
      --#{$prefix}alert-color: #{$primary-alt} !important;

      .alert-link {
        color: currentColor !important;
      }

      .btn-close {
        background: transparent
          escape-svg($btn-close-bg-alt)
          center /
          $btn-close-width
          auto
          no-repeat; // include transparent for button elements
      }
    }

    .alert-discreet-#{$name} {
      --#{$prefix}alert-color: #{$color} !important;
      --#{$prefix}alert-bg: #{rgba($color, $discreet-btn-gradient)};

      .alert-link {
        color: $color !important;
      }

      .btn-close {
        @include btn-close-cross($color);
      }
    }
  }

  .alert-#{$name} {
    --#{$prefix}alert-bg: #{$color};
    --#{$prefix}alert-border-color: #{$color};
  }

  .alert-outline-#{$name} {
    --#{$prefix}alert-bg: transparent;
    --#{$prefix}alert-color: #{$color};
    --#{$prefix}alert-border-color: #{$color};

    .alert-link {
      --#{$prefix}alert-color: #{$color};
    }

    .btn-close {
      @include btn-close-cross($color);
    }
  }
}
