//
$timeline-day-font-size: 0.875rem !default;
$timeline-border-width: 1px !default;
$timeline-border-color: $primary !default;
$timeline-padding: 0.5rem !default;
$timeline-dot-size: 0.5rem !default;
$timeline-active-color: $primary !default;
$timeline-active-font-weight: $font-weight-medium !default;
$timeline-active-background: rgba($primary, map-get($opacities, '05')) !default;

.timeline {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &:not(.timeline-alternative) .timeline-item {
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: $timeline-dot-size;
      width: $timeline-dot-size;
      background: currentColor;
      border-radius: 50%;
      bottom: -0.5 * $timeline-dot-size;
      left: -0.5 * $timeline-dot-size;
    }

    &:last-child {
      border-bottom: none;
    }

    .timeline-day,
    .timeline-date {
      margin-left: -0.5 * $timeline-dot-size;
    }
  }
}

.timeline-item {
  flex: 1;
  padding-bottom: 2 * $timeline-padding;
  border-bottom: $timeline-border-width solid $timeline-active-color;
  color: $timeline-active-color;
  position: relative;

  &.inactive {
    border-bottom-color: $timeline-inactive-color;
    color: $timeline-inactive-color;

    &::before {
      background-color: $secondary !important;
    }

    .timeline-day,
    .timeline-date {
      color: $secondary;
    }
  }
}

.timeline:not(.timeline-vertical) .timeline-item.active {
  background: $timeline-active-background;
}

.timeline-day {
  display: block;
  font-size: $timeline-day-font-size;
  text-transform: uppercase;
}

.timeline-date {
  display: block;
  font-weight: $font-weight-normal;

  .active & {
    font-weight: $timeline-active-font-weight;
  }
}

.timeline-alternative {
  .timeline-item {
    padding: 0.5 * $timeline-padding 3 * $timeline-padding;
    &::before {
      content: '';
      display: block;
      height: $timeline-dot-size;
      width: $timeline-dot-size;
      background: currentColor;
      position: absolute;
      top: $timeline-padding;
      left: $timeline-padding;
    }
  }
}

.timeline-vertical {
  flex-direction: column;
  align-items: flex-start;

  .timeline-item {
    border-bottom: none;
    padding-left: 2 * $timeline-padding;
    border-left: $timeline-border-width solid $timeline-active-color;
  }

  .timeline-item::after {
    bottom: auto;
    top: -0.5 * $timeline-dot-size;
  }

  .timeline-day {
    margin-top: -1 * $timeline-dot-size;
  }

  &:not(.timeline-alternative) .timeline-item:last-child {
    border-left: none;
  }
}
