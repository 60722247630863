@mixin pagelink-styles($background, $bordercolor, $color) {
  background: $background;
  border-color: $bordercolor;
  color: $color;
}

@mixin pagelink-active($background, $bordercolor, $color) {
  .page-item.active .page-link,
  .page-link:focus,
  .page-link:active,
  .page-link:hover {
    @include pagelink-styles($background, $bordercolor, $color);
  }
}
