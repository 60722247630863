@import 'bootstrap/scss/list-group';

// Override active item

.list-group-item {
  --#{$prefix}list-group-border-width: 0;
  margin-top: 0 !important;

  &.active {
    color: inherit;
    border-bottom-width: 0;
    border-top-width: 0;
  }
}

.list-group-flush .list-group-item {
  border-bottom-width: 1px;
}

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $name, $color in $theme-colors {
  .list-group-item-#{$name} {
    color: $color !important;
    margin-bottom: 0;
    background-color: transparent;

    &.list-group-item-action {
      &:not(.disabled) {
        &:hover,
        &:focus,
        &.focus {
          color: $color !important;
          background-color: get-interaction-color("hover", $color);
        }
      }

      &.active {
        color: $color !important;
        background-color: get-interaction-color("selected", $color);
        border-left-color: $color;
      }

      &.disabled,
      &:disabled {
        color: get-interaction-color("disabled", $color)  !important;
      }
    }
  }
}

// ADAPTATED COLORS
@each $background in $adaptive-backgrounds {
  @each $name, $color in map-get($adaptive-palette, $background) {
    $adapted_color: map-get(map-get($adaptive-palette, $background), $name);
    .#{$background} .list-group-item-#{$name} {
      --#{$prefix}list-group-color: #{$adapted_color};

      &.list-group-item-action {
        &:not(.disabled) {
          &:hover,
          &:focus,
          &.focus {
            --#{$prefix}list-group-color: #{$adapted_color};
            background-color: get-interaction-color("hover", $adapted_color);

          }
        }

        &.active {
          --#{$prefix}list-group-color: #{$adapted_color};
          background-color: get-interaction-color("hover", $adapted_color);
          border-left-color: $adapted_color;
        }

        &.disabled,
        &:disabled {
          --#{$prefix}list-group-color: #{get-interaction-color("disabled", $adapted_color)};
        }
      }
    }
  }
}
